.button {
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 12px;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    margin: 8px 0 !important;
    height: 48px;
    width: 48px;
    position: relative;
    cursor: pointer;
}

.buttonColorSelect {
    background: #FFFFFF !important;
}

.buttonColor {
    background: var(--partner-theme-color-shaded, #637EF5) !important;

    &:hover {
        background: var(--partner-theme-color-hover, linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), linear-gradient(0deg, #637EF5, #637EF5)) !important;
    }
}

.buttonSmooth {
    width: 24px;
    height: 24px;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    transition: all 500ms ease;
}

.menuButtonColorSelect svg path{
    color: #637EF5;
    fill: #637EF5;
}

.menuButtonColor svg path{
    color: #fff;
    fill: #ffffff;
}

.notificationIcon {
    position: absolute;
    right: 1px;
    top: 1px;
}