.contactListItem {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    &:hover {
        background-color: #eeeef1;
        cursor: pointer;
    }
}

.contactListItemSelected {
    background: linear-gradient(0deg, #D7F6EA, #D7F6EA), #88B8FF;
}

.contactChosenWrapper {
    padding-left: 10px;
    margin-right: 30px;
    width: 18px;
}

.contactItemChosenCheck {
    :global {
        svg {
            height: 14px;
            width: 18px;
        }
    }
}

.chatItem {
    flex-grow: 2;
    background-color: transparent;
    &:hover {
        background-color: transparent;
    }
}
