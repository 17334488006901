@import "src/styles/colorVars";
@import "src/styles/numbers";

.assign-to-modal-wrapper {
    background-color: white;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.rtl {
        direction: rtl;

        [class*="conversation-list-item"] {
            text-align: right;
        }
    }
}

.assign-to-modal-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 32px;
}

.assign-to-modal-title__text {
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: $primary_black;
}

.assign-to-modal-body{
    overflow-y: auto;
    position: relative;
    height: 100%;
    width: 100%;

    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;
}

.assign-to-modal-body__content {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 20px;
}

.assign-to-modal-body__title {
    display: flex;
    padding: 0 12px;

    & > span {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    [class="ant-badge-status-text"] {
        margin-left: 0;
    }
}

.assign-to-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 2px solid $primary_grey_border;
    height: 104px;
    padding: 28px 40px;
}

.assign-to-modal-footer__button {
    width: 168px;
    max-width: 168px;
    min-height: 48px;
    flex-basis: auto;
    flex-grow: unset;
    margin: 0 12px;
}

.assign-to-modal-wrapper .ant-tabs-nav-wrap {
    padding: 0 32px;

    [class="ant-tabs-nav-list"] {
        gap: 16px;
    }

    @media (max-width: $mobile_sm_width) {
        padding: 0 24px;
    }
}

.assign-to-modal-wrapper .ant-tabs-tab {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    margin: 0;

    color: $primary_black;
    opacity: 0.6;

    @media (max-width: $mobile_sm_width) {
        & + .ant-tabs-tab {
            margin-left: 0;
        }
    }
}

.assign-to-modal-wrapper .ant-tabs-tab:hover {
    color: $primary_green;
}

.assign-to-modal-wrapper .ant-tabs-tab-active {
    color: $primary_green !important;
    opacity: 1;
}

.assign-to-modal-wrapper .ant-tabs-ink-bar {
    background: $primary_green !important;
}

.assign-to-modal-footer__button.ant-btn:active,
.assign-to-modal-footer__button.ant-btn:focus {
    background: #f5f5f5 !important;
    color: rgba(0, 0, 0, 0.25) !important;
}

.assign-to-button.ant-btn:hover {
    background-color: #00AB66;
}
