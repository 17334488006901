@import "src/styles/colorVars";

.scroll-button-container {
    height: 50px;
    width: 50px;
    border: none;
    position: absolute;
    bottom: 8px;
    right: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    &.rtl {
        right: unset;
        left: 8px;
    }
}

.unread-counter {
    position: absolute;
    left: 2rem;
    top: 2px;
    box-shadow: 0 0 1px 2px #ffffffa6;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    background-color: #0FB878;
}

.unread-counter-number {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;

    margin-top: 12px;
    color: white;
}

.scroll-button {
    border-radius: 50%;
    background: #F3F3F3;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12);
    height: 35px;
    width: 35px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.scroll-down-arrow {
    margin-top: 2px;
    transform: rotate(90deg);
}

.invertColors {
    color: $primary_white;
}