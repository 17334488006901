.contact-filter-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 32px;
}

.contact-filter-search-icon svg{
    height: 20px;
    width: 20px;
    margin-right: 4px;
}

.department-filter-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 45%;
}
