@import 'src/styles/colorVars';
@import 'src/styles/numbers';

.filterContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    position: sticky;
    top: 0;
    background: $primary_white;
    z-index: 10;
}

.filtersWrapper {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    gap: 16px;
    padding: 16px 20px;
    border-bottom: 1px solid $primary_grey_border;

    @media (max-width: $mobile_sm_width) {
        column-gap: 8px;
    }
}

.templateSearch {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 12px;
    position: relative;

    @media (max-width: $mobile_sm_width) {
        grid-column: 1 / 4;
    }
}

.templateSearchInput {
    background-color: #f5f5f580;
    padding-right: 30px;

    &::placeholder,
    &:focus::placeholder {
        text-align: left;
    }
}

.templateSearchIcon svg {
    height: 24px;
    width: 24px;
    fill: $primary_grey;
}

.cleanSearchInput {
    height: 20px;
    width: 20px;
    cursor: pointer;

    &.rtl {
        left: 12px;
        right: unset;
    }
}

.genFilterText {
    margin-right: 12px;
}

.genFilter {
    width: 156px;

    &.rtl {
        [class='ant-select-selection-item'] {
            direction: rtl;
            padding-right: 0 !important;
            padding-left: 18px !important;
            justify-content: right !important;

            &::after {
                content: none !important;
            }
        }
        [class='ant-select-arrow'] {
            right: auto;
            left: 18px;
        }
    }
}

.genOption {
    &.rtl {
        direction: rtl;
    }
}

.genFilterContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

@media (max-width: $mobile_sm_width) {
    .filtersWrapper {
        align-items: flex-end;
    }

    .genFilterContainer {
        width: 100%;
        min-width: 140px;
    }

    .depFilter {
        width: 100%;
    }

    .genFilter {
        width: 100%;
    }
}
