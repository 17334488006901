@import "src/styles/colorVars";

.modalContainer {
  [class="ant-modal-body"]{
    padding: 64px;
    display: flex;
    justify-content: center;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;
  max-width: 80%;
  width: 100%;
  height: 100%;
}

.avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  object-fit: cover;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 8px;
}

.phone {
  font-size: 14px;
  color: $secondary_black;
  line-height: 14px;
}

.message {
  margin-top: 24px;

  &.rtl {
    direction: rtl;
  }
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 48px;
}

.actionButton {
  min-height: 48px;
  height: 48px;
  width: 168px;
  font-weight: bold;
  border: 1px solid $primary_grey_border !important;
}
