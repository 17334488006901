@import 'src/styles/colorVars';

.container {
    display: flex;
    flex-direction: column;
}

.error {
    font-size: 14px;
    line-height: 16px;
    color: $primary_red;
}

.labelContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 4px;
    margin-bottom: 20px;

    p {
        margin-bottom: 0;
    }
}

.checkboxContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    svg {
        width: 16px;
        height: 16px;
        color: $primary_green;
    }
}

.channelFormButton {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 40px !important;
    line-height: 24px !important;
    font-size: 16px !important;
    font-weight: normal !important;
    width: fit-content;
    padding: 8px 16px !important;
    margin-top: 8px;

    svg {
        margin-right: 8px;
    }
}

.colorSelectOption {
    width: 80px;
    height: 20px;
    margin: 0 16px;
}

.webhookContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

.webhookStatusContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    &.connected {
        .webhookDot {
            background-color: $primary_green;
        }
        .webhookStatus {
            color: $primary_green;
        }
    }
}

.webhookStatus {
    color: $tertiary_black;
}

.webhookDot {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: $tertiary_black;
    animation: pulse 2s infinite;
}

.credentialsContainer {
    margin-bottom: 1rem;

    legend {
        width: auto;
        font-size: 0.875rem;
    }

    fieldset {
        padding: 1rem;
        border: 1px solid $antd_border;
        margin: 0;
    }

    button[type='submit'] {
        display: none;
    }
}

.iconSelectOption {
    width: 22px;
    min-width: 22px;
    height: 22px;
}