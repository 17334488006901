@import "src/styles/colorVars";

.container {
    min-height: 45px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    background-color: $tertiary_grey;
}

.container:hover {
    cursor: pointer;
}

.coloredLine {
    width: 4px;
    min-width: 4px;
    border-radius: 8px 0 0 8px;
    margin-right: 4px;
    background-color: $primary_green;

    &.incoming {
        background-color: $primary_blue;
    }

    &.invertColors {
        background-color: $secondary_black;
    }
}

.shadow {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.12);
}

.whatsapp {
    background-color: #0a9e66;
}

.messenger {
    background-color: #0e60ac;
}

.instagram {
    background: linear-gradient(259.81deg, #ac0f6d -5.58%, #bf4d35 120.11%);
}
