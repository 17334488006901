@import "src/styles/colorVars";

.container {
  background-color: rgba(253, 253, 253, 0.95);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: grid;
  grid-template-rows: 1fr;
}

.box {
  display: grid;
  grid-template-rows: auto 1fr auto;
  border: 1.5px solid $primary_grey;
  margin: 12px;
  gap: 16px;
  overflow: hidden;
}

.inner {
  height: 100%;
  width: 100%;
  text-align: center;
  color: $secondary_black;
  font-size: 36px;
  line-height: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0 16px;
}

.innerIcon {
  margin-bottom: 32px;

  svg {
    width: 96px;
    height: 96px;
    color: $primary_white;
  }
}

.compactIcon {
  svg {
    width: 20px;
    height: 20px;
    color: $primary_white;
  }

}

.innerTitle {
  font-size: 20px;
}

.innerSubtitle {
  font-size: 16px;
  text-transform: uppercase;
}

.placeholder {
  width: 24px;
  height: 24px;
}

.header {
  height: 58px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: 8px;
  background-color: $primary_grey;
  overflow: hidden;
}

.closePreview {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  text-align: center;
  line-height: 24px;
  transition: 500ms;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.nameWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 16px;
  color: $primary_black;
  font-size: 14px;
}

.name {
  word-break: break-word;
}

.nameDetails {
  color: $secondary_black;
  font-size: 11px;
}

.innerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;

  &.compactVideo {
    pointer-events: none;
  }
}

.loadingIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 48px;
  height: 48px;
}

.pdfDocument {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdfPage {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 4px 8px;
}

.noPreviewContainer {
  background-color: $primary_grey;
  border-radius: 8px;
  padding: 24px 48px;
}

.compactContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  text-transform: uppercase;
  justify-content: center;
  background-color: $primary_blue;
  font-weight: bold;
  height: 100%;
  width: 100%;
  
  span {
    color: $primary_white;
    font-size: 8px;
    line-height: 8px;
  }
}

.audioPlayer {
  border: 1px solid $primary_grey;
  border-radius: 28px;
  height: 54px;
  width: 100%;
}

.controlsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  width: 100%;
  min-width: 0;
  overflow-x: scroll;

  img, video {
    width: 100%;
    object-fit: cover;
  }
}

.controlsBoxPreview {
  width: 50px;
  min-width: 50px;
  height: 50px;
  position: relative;
  border-radius: 2px;
  border: 4px solid transparent;

  &.selected {
    cursor: pointer;
    border-color: $primary_grey;
    
    .innerContent {
      filter: brightness(0.5);
    }

    .deleteIcon {
      visibility: visible;
    }
  }
}

.controlsAdd {
  width: 50px !important;
  min-width: 50px;
  height: 50px !important;
  border-width: 2px !important;
  border-radius: 2px;
  
  &:hover {
    svg, path {
      transition: fill .3s ease;
      fill: $primary_green;
    }
  }

}

.deleteIcon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  justify-content: center;
  
  svg {
    width: 24px;
    height: 24px;
    fill: $primary_grey;
  }
}