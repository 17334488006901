.contactList {
    display: flex;
    flex-direction: column;
    padding: 0 32px;
}

.contact-item-chosen-check {
    width: 10px;
    justify-self: flex-end;
}
