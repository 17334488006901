@import "src/styles/numbers";
@import "src/styles/colorVars";

.conversationSearch {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 0 16px;
}

.conversationSearchFulltext {
  margin: -10px 16px 0;
  font-size: 13px;
}

.cleanSearchInput {
  height: 20px;
  width: 20px;
  cursor: pointer;

  &.rtl {
    right: auto;
    left: 47px;
  }
}

.chatSearchIcon svg{
  height: 20px;
  width: 20px;
  fill: grey;
}

.hidden {
  display: none!important;
}

.openMenuIcon {
  width: 32px;
  height: 32px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  fill: $secondary_black;

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(196, 196, 196, 0.3);

  }
}

@media (max-width: $mobile_width) {
   .conversationSearch {
      width: 100vw;
      height: 60px;
      border-bottom: 1px solid $antd_border;
  }
}