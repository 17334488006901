@import "src/styles/numbers";
@import "src/styles/colorVars.scss";

.messenger {
  display: flex;
  width: 100%;
  height: calc(100% - 72px);
  background: #eeeef1;
}

.container {
  padding: 10px;
}

.scrollable {
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.sidebar {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
  width: 380px;
  min-width: 380px;
}

.content {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}

.noActiveChat {
  position: absolute;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  background-color: #FAFAFA;
  z-index: 9999;
}

.footer {
  grid-column-start: 2;
  background: white;
}

.lowerBlock {
  position: relative;
  bottom: 0;
  width: 100%;
  min-width: 170px;
  margin-top: auto;
  z-index: 10;
}

.moreButtonMobile {
  display: none !important;
}

.scrollToUnreadAnchor {
  z-index: 10;
}

.loadingWrapper {
  height: 100%;
}

.messageListNoDropContainer {
  min-height: calc(100% - 264px);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &.filePreview {
    min-height: calc(100% - 314px + 64px + 66px);
  }

  &.replyOpen:not(&.filePreview) {
    min-height: calc(100% - 376px);
    max-height: calc(100% - 342px);
  }

  &.withDefaultTemplate {
    min-height: calc(100% - 264px - 2 * 42px);
  }
}

.userInfoWrap {
  position: relative;
  background: $secondary_grey_border;
  height: 72px;
  min-height: 72px;
  min-width: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 0 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

@media (min-width: $mobile_width) and (max-width: $desktop_width) {
  .sidebar {
    width: 316px;
    min-width: 316px;
  }
}

@media (max-width: $mobile_width) {
  .content {
    width: 100vw;
    min-width: 100vw;
  }

  .messenger.chatOpen {
    height: 100%;
  }

  .moreButtonMobile {
    display: block !important;
    margin-right: 20px;
  }
}
