@import "src/styles/colorVars";

.take-chat-container {
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    border: 1px solid #EAEAEA;
    height: 66px;
    position: relative;
}

.info-block {
    display: flex;
    flex-direction: row;
}

.text-block {
    display: flex;
    flex-direction: row;
}

.agent-info-block {
    display: flex;
    gap: 12px;
}

.agent-avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-top: 8px;
}

.agent-info {
    margin: 8px 0;
}

.agent-name {
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #383C3A;
}

.agent-status {
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #888A89;
    margin-top: 3px;
    text-transform: capitalize;
}

.info-circle {
    height: 13px;
    margin: 14px 7px 0 0;
}

.text {
    font-family: SF UI Text;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    margin-top: 10px;
}

.accept-button {
    display: flex !important;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.accept-button.take-button:active,
.accept-button.take-button:focus {
    background: #f5f5f5 !important;
    color: rgba(0, 0, 0, 0.25) !important;
}

.take-chat-button-bold-text {
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #FFFFFF !important;
}

.take-chat-button-text {
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.02em;
    color: #FFFFFF !important;
    opacity: 0.8;
    display: flex !important;
}

.take-chat-button-text-rtl {
    direction: rtl;
}

.take-chat-secondary-options-button {
    border-left: 1px solid white !important;
}

.take-chat-secondary-options-container {
    position: absolute;
    bottom: 60px;
    right: 24px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #DEDEDE;
    border-radius: 2px;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 50;

    &.rtl {
        right: auto;
        left: 24px;
    }
}

.take-chat-radio-header {
    padding: 14px !important;
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #000000;
}

.take-chat-radio-divider {
    margin: 0 !important;
}

.take-chat-radio-option {
    padding: 14px !important;
    width:100%;
    margin: 0;
}

.take-chat-radio-option:hover {
    background-color: #F3F3F3;
}

.take-chat-radio-option-text {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
}

.take-chat-department-color {
    height: 8px;
    width: 16px;
    border: 1px solid #FFFFFF;
    border-radius: 2px;
    margin: 8px 4px 0 4px;
}

.take-chat-dep-button-color {
    margin: 4px 4px;
}

.invertColors {
    color: $primary_white !important;
}

.buttons-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}