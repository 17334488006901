@import 'src/styles/colorVars';

.wrapper {
    display: grid;
    grid-template-rows: auto 1fr;
    border-left: 1px solid $primary_white;
    height: 100%;

    &.rtl {
        border-right: 1px solid $primary_white;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    height: 72px;
    min-height: 72px;
    position: relative;
    min-width: 0px;
    z-index: 20;
}

.body {
    display: flex;
    flex-direction: column;
    padding: 0;
    position: relative;
    background-color: $primary_white;
}

.title {
    font-size: 11px;
    color: $primary_black;

    &.inverted {
        color: $primary_white;
    }
}

.closeModal {
    width: 32px;
    height: 32px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    svg {
        width: 16px;
        height: 16px;
    }

    svg path {
        fill: $primary_grey;
    }

    &.inverted {
        svg path {
            fill: $secondary_black;
        }
    }

    &:hover {
        cursor: pointer;
        background-color: rgba(196, 196, 196, 0.3);
    }
}

.loadingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    [class*='anticon'] {
        font-size: 48px;
        width: 48px;
        height: 48px;
        color: $primary_green !important;
    }
}

.resultsList {
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100% - 72px);
    position: absolute;
    top: 72px;
    left: 0;
    right: 0;
    z-index: 2;

    .messages-search-item {
        width: 100%;
    }
}

.searchInput {
    height: 40px;
    min-height: 40px;
    background-color: $primary_grey !important;
    border-radius: 8px !important;

    &:not(&.mobileSearchInput) {
        input {
            border-radius: 4px;
            background-color: $primary_grey;

            &:hover,
            &:focus {
                background-color: $primary_grey;
            }
        }
    }
}

.mobileSearchInput {
    margin-bottom: 0;
    background-color: transparent !important;

    input {
        color: $primary_black;
    }

    &.inverted {
        input {
            color: $primary_white;
        }
        svg {
            fill: $primary_white;
        }
    }
}

.mobileWrapper {
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    align-items: center;
    gap: 16px;
    width: 100vw;
}

.arrowBack {
    svg {
        width: 16px;
        height: 16px;
        fill: $primary_grey;
    }

    &.inverted svg {
        fill: $secondary_black;
    }
}

.controls {
    display: flex;
    align-items: center;
    gap: 12px;

    svg {
        width: 28px;
        height: 28px;
        cursor: pointer;

        path {
            fill: $primary_grey;
        }
    }

    &.inverted {
        svg path {
            fill: $secondary_black;
        }
    }

    span[class='anticon'][disabled] {
        pointer-events: none;
        opacity: 0.5;
    }
}

.noResults {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.resultsCount {
    display: flex;
    align-items: center;
    min-width: 60px;
    color: $primary_black;

    &.inverted {
        color: $primary_white;
    }
}

.wrapper,
.mobileWrapper {
    [class='ant-input-suffix'] {
        [aria-label='close-circle'] svg {
            fill: $primary_black;
        }
        [aria-label='loading'] svg {
            fill: $primary_green;
        }
    }
}

.inputWrapper {
    display: flex;
    padding: 16px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25)
}

.resultsWrapper {
    display: flex;
    padding: 16px;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.mobileInputWrapper {
    display: inline-flex;
    position: relative;
}

.mobileInputBackground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $primary_grey;
    border-radius: 8px;
    opacity: 0.3;
    pointer-events: none;
}