@import "src/styles/numbers";

.topSidebar {
  display: flex;
  flex-direction: row;
  height: 72px;
}
@media (max-width: $mobile_width) {
  .topSidebar {
    width: 100vw;
    flex-direction: row;
    overflow-x: auto;
    scrollbar-width: 0px;
    -ms-overflow-style: none;
  }

  .topSidebar::-webkit-scrollbar {
    display: none;
  }
}
