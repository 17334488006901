:root {
    --link-color: #1a237e;
    --link-color-hover: #3f51b5;
    --primary-green: #02ca79;
}

html,
body {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.big-button {
    height: 50px;
    border: transparent;
    border-radius: 2px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
}

.white-button {
    background-color: #ffffff;
    border: 1px solid #eaeaea;
}

.white-button:hover {
    color: var(--primary-green);
    border: 1px solid var(--primary-green);
}

.ant-btn.white-button:active,
.ant-btn.white-button:focus {
    background-color: #ffffff;
    color: black;
    border: 1px solid #eaeaea;
}

.ant-btn.white-button-disabled {
    border: 1px solid transparent;
}

.ant-btn:not([class*="ant-btn-dangerous"]):not([disabled]):hover,
.ant-btn:not([class*="ant-btn-dangerous"]):not([disabled]):focus {
    color: inherit;
    border-color: var(--primary-green);
}

.ant-input:hover {
    border-color: var(--primary-green);
}

.ant-picker-focused,
.ant-picker:hover {
    border-color: var(--primary-green);
}

.ant-switch-checked {
    background-color: var(--primary-green);
}

.big-green-button {
    height: 50px;
    background-color: var(--primary-green);
    color: #ffffff;
    border: transparent;
    border-radius: 2px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
}

.ant-btn-primary.big-green-button:focus {
    background-color: var(--primary-green) !important;
    border: transparent;
}

.big-green-button:hover {
    background-color: #00ab66;
    color: white;
    border: transparent;
}

.big-blue-button {
    height: 40px;
    background-color: #546bd6;
    color: #ffffff;
    border: transparent;
    border-radius: 2px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
}

.big-blue-button:hover {
    background-color: #4b65dd;
    color: white;
}

.--antd-wave-shadow-color {
    background: var(--primary-green);
}

.ant-btn-primary,
.ant-btn-primary:focus {
    background: var(--primary-green);
    border-color: var(--primary-green);
}

.ant-btn-primary:hover {
    background-color: #00ab66;
}

.ant-btn-primary::selection {
    background: var(--primary-green);
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary-green);
    border-color: var(--primary-green);
}

.ant-select-selector {
    width: auto !important;
}

.ant-select-selection-item {
    display: flex;
    align-items: center;
    gap: 8px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--primary-green);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary-green);
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: var(--primary-green) !important;
    border-color: var(--primary-green) !important;
}

.ant-checkbox-inner::after,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
    border-color: var(--primary-green);
}

.ant-radio-checked .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner {
    border-color: var(--primary-green);
}

.ant-radio-inner:after {
    background-color: var(--primary-green);
}

.ant-tabs-tab:hover {
    color: var(--primary-green);
}

.ant-tabs-ink-bar {
    background-color: var(--primary-green);
}

.notification-new-version {
    cursor: pointer;
}
.notification-new-version.rtl {
    direction: rtl;
    border: 5px solid red;
}
.notification-new-version.rtl .ant-notification-notice-close {
    left: 22px;
    right: auto;
}
.notification-new-version.rtl .ant-notification-notice-message,
.notification-new-version.rtl .ant-notification-notice-description {
    margin-left: auto;
    margin-right: 48px;
    padding-right: 0;
}

*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

*::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-track {
    background: hsla(0, 0%, 100%, 0.1);
}
