@import "src/styles/numbers";
@import "src/styles/colorVars";

.crm-wrapper {
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100%;
    overflow-y: auto;
    border-top: 1px solid $secondary_grey_border;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: $mobile_width) {
        height: 100%;
        display: grid;
        grid-template-rows: auto auto 1fr;
        border-top: none;
        padding: 12px;

        &.crm-noData {
            grid-template-rows: auto 1fr;
        }
    }
}

.crm-logo-circle {
    border-radius: 50%;
    border: 1px solid $secondary_grey_border;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.crm-logo-circle-image {
    max-height: 80%;
    max-width: 80%;
}

.crm-link-title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 24px 24px;

    @media (max-width: $mobile_width) {
        padding: 24px 12px;
      }
}

.crm-link-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}

.crm-title-text {
    text-transform: capitalize;
}

.link-button-circle {
    height: 31px;
    width: 31px;
    border-radius: 50%;
    border: 1px solid #EAEAEA;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    path {
        transition: fill 0.3s;
    }

    &:not([disabled]):hover {
        path {
            fill: $primary_green;
        }
        
    }
}

.ticket-title {
    border-bottom: 2px solid #EAEAEA;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 24px;
}

.ticket-title::after {
    content: '';
    border-bottom: 2px solid #30C498;
    width: 56px;
    right: 80%;
    top: 23px;

    position: relative;
}

.user-status {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 12px 24px;

    border-top: 2px solid #EAEAEA;
    border-bottom: 2px solid #EAEAEA;

    @media (max-width: $mobile_width) {
        padding: 12px;
      }
}

.ant-btn.link-button-circle:hover,
.ant-btn.link-button-circle:focus {
    background-color: #ffffff !important;
    outline: none;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
}

.close-field svg{
    fill: #EB584E;
}

.ant-select.select-active-status {
    margin-right: 10px;
}

.crm-blocker-wrapper {
    position: relative;
    width: 100%;
    height: calc(100% - 162px);
}

.crm-preloader-wrapper {
    height: calc(100% - 162px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.crm-preloader {
    align-self: center;
}

.crm-fail-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 176px;
    width: 100%;
    margin: auto;
    padding: 12px 0;
    border-bottom: 2px solid $primary_grey_border;
}

.no-contact-wrapper {
    display: flex;
    flex-direction: column;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 320px;
}