.statusText {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    letter-spacing: -0.15px;
    color: #929292;
    margin: 0;
    max-width: 100px;
    text-transform: uppercase;
    justify-self: end;
}

.listItemText {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.5px;
    width: 70px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;

    &.longText {
        align-items: start;
    }
}

.reversed {
    direction: rtl;

    &.tabTitle {
        text-align: right;
    }
}

.grey {
    color: #929292;
}

.blue {
    color: #54ADDF;
}

.yellow {
    color: #f1b602;
}

.green {
    color: #0FB878;
}

.tabTitle {
    text-align: left;
    font-size: 12px;
    line-height: 133%;
    text-transform: uppercase;
}

.activeTab {
    font-weight: bold;
}
