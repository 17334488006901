@import "src/styles/colorVars";
@import "src/styles/numbers";

$tabletSmall: 1025px;
$tabletLarge: 1300px;

.richMessageBlock {
  margin-bottom: 5px;
  max-width: 80%;

  @media (min-width: $mobile_sm_width) and (max-width: $tabletSmall) {
    max-width: 65%;
  }

  @media (min-width: $tabletSmall) and (max-width: $tabletLarge) {
    max-width: 80%;
  }

  @media (min-width: #{$tabletLarge + 1}) and (max-width: $desktop_width) {
    max-width: 75%;
  }

  @media (min-width: $desktop_width) {
    max-width: 65%;
  }

  &.bulkWithRichParams {
    max-width: 336px;
  }
}

.richMessageBox {
  background: #47b972;
  border-radius: 8px;
  padding: 5px 0px;
  color: #dcf8c6;
  display: inline-block;
  min-width: 100px;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12);

  &.fullWidth {
    width: 100%;
  }
}

.error {
  border: 1px solid $primary_red;
}

.richMessageMainBlock {
  padding: 0px 10px;
  right: 0;
}

.receiver {
  position: relative;
  float: right;
  margin-bottom: 2px;
  margin-inline: 3px;
  width: 100%;
  bottom: -4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 3px;
}

.timestampUser {
  letter-spacing: 0;
  position: relative;
  bottom: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  word-wrap: break-word;
  margin-bottom: 0;
  width: 70px;
  color: $secondary_black;

  display: flex;
  justify-content: flex-end;
}

.timestampUserWhite {
  color: $primary_white;
}

.richMessageContainer {
  padding: 0 8px;
}