@import "src/styles/numbers";
@import "src/styles/colorVars";

.resolveChatContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 320px;
    min-width: 264px;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    background: white;
    border: 1px solid #EAEAEA;
    height: 130px;
    z-index: 15;
}

@media (max-width: $mobile_width) {
    .hiddenResolveChatContainer {
        display: none;
    }
    .resolveChatButtonText {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 92px;
    }
    .resolveChatContainer{
        height: fit-content;
        max-height: fit-content !important;
        top: 72px;
        right: 8px;
    }
    .resolveChatButtonsBlock {
        display: flex;
        flex-direction: column !important;
    }
}

.notifyClientCheckbox {
    margin-left: auto;
    margin-right: 5px;
    font-size: 0.9em;
    display: flex;
    align-items: baseline;
}

.notifyClientText {
    margin: 0;
}

.resolveChatButtonsBlock {
    display: flex;
    flex-direction: column;
}

.resolveButtonWrapper {
    display: flex;
    flex-direction: row;
}

.resolveButton {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    margin: 5px 0;
    min-width: 100px;
    height: 40px !important;
    min-height: 40px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: background 0.15s ease;
    color: $primary_black !important;

    &[disabled] {
        background: #F5F5F5 !important;
        color: #00000040 !important;
    }
}

.secondaryOptionsButton {
    height: 40px !important;
    width: 40px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    border-left: 1px solid white !important;
    margin: 5px 0;

    &[disabled] {
        background: #F5F5F5 !important;
        color: #00000040 !important;
    }
}

.loader {
    height: 20px;
    width: 20px;
}

.sendMessageContainer {
    position: absolute;
    bottom: 60px;
    right: 32px;
    display: flex;
    flex-direction: column;
    background-color: $primary_white;
    border: 1px solid $primary_grey;
    border-radius: 2px;
    width: 256px;
}

.radioHeader {
    padding: 14px !important;
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: $primary_black;
}

.radioDivider {
    margin: 0 !important;
}

.radioOption {
    display: grid !important;
    grid-template-columns: auto 1fr auto;
    padding: 14px !important;
    width:100%;
    margin: 0;
}

.radioOption:hover {
    background-color: $tertiary_grey;
}

.radioOptionText {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: 100%;
    flex-flow: row wrap;

    svg {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    &.disabled svg {
        cursor: not-allowed;
    }
}

.buttonBoldText {
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.02em;
    color: $primary_black;
}

.buttonText {
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: $primary_black;
    opacity: 0.8;
    display: flex !important;
}

.buttonGreyText {
    opacity: 0.25;
}

.invertColors {
    color: $primary_white !important;
}

.selectBotModal {
    width: 100%;
    border-bottom: none !important;

    [class="ant-list-items"] {
        padding-bottom: 12px;
    }

    li[class*="ant-list-item"] {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
            background-color: $tertiary_grey;
        }
    }
}

.arrowOpen {
    transform: rotate(180deg);
}