@import "src/styles/colorVars";

.addDepartmentLabeledInput {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.addDepartmentError {
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: rgba(218, 63, 63, 0.96);
    margin-top: 3px;
}

.addDepartmentAgentsList {
    height: 300px;
    overflow-y: auto;
}

.addDepartmentAgent {
    width: 100%;
    /*border: 1px solid;*/
    box-shadow: 0px 1px 1px rgb(0 0 0 / 12%);
    padding: 12px 10px;
    display: flex;
    align-items: center;
}

.extraInfo {
    font-size: 12px;
    color: $tertiary_black;
    display: inline-block;
    margin-left: 8px;
}

.slaContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 4px;
    margin-top: 4px;
}

.slaTimePicker {
    min-width: 150px;
}

.slaSwitch {
    &[class*="ant-switch-checked"] {
        background: $primary_green !important;
    }
}