@import "src/styles/colorVars.scss";

.container {
    display: flex;
    align-items: center;
    gap: 4px;
    color: $secondary_black;

    &.inverted {
        color: $primary_white;
        opacity: 0.85;
    }
}

.accountName {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
}

.copyIcon {
    margin-left: 4px;
    cursor: pointer;

    &.rtl {
        margin-left: 0px;
        margin-right: 4px;
    }
}