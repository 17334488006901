@import 'src/styles/colorVars';

.specialContainer {
    display: flex;
    flex-direction: column;
    color: $primary_black;

    &.inverted {
        color: $primary_white;
    }
}

.specialMessageBox {
    border-radius: 8px;
    padding: 5px 0px;
    color: #dcf8c6;
    display: inline-block;
    min-width: 100px;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12);

    &.fullWidth {
        width: 100%;
    }
}

.receiver {
    position: relative;
    float: right;
    margin-bottom: 2px;
    width: 100%;
    bottom: -4px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 3px;
}

.timestampUser {
    letter-spacing: 0;
    position: relative;
    bottom: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    word-wrap: break-word;
    margin-bottom: 0;
    width: 70px;
    color: $secondary_black;

    display: flex;
    justify-content: flex-end;
}

.timestampUserWhite {
    color: $primary_white;
}

.sentContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    width: fit-content;

    svg {
        width: 16px;
        height: 16px;
        fill: $primary_black;
    }
}

.yamlPreviewContainer {
    display: flex;
    min-height: 150px;
    max-width: 320px;
    font-size: 14px;
}

.tooltipBodyContainer {
    [class="ant-tooltip-inner"] {
        width: 200px;
        overflow-y: auto;
        text-align: center;
    };
}