@import "src/styles/numbers";
@import "src/styles/colorVars";

.resultsList {
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100% - 170px);

  &.hideFavorites [class="favorite"] {
    display: none;
  }
}

.resultsListLong {
  height: calc(100% - 72px);
}

.filtersContainer {
  display: flex;
  flex-direction: column;

  &.withBorder {
    border-bottom: 1px solid $antd_border;
  }
}

.filtersBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 12px 0;
  gap: 12px;
  padding: 0 16px;
  min-width: 100%;

  & > div {
    min-width: 100%;
    width: 100%;
  }
}

.resultsListTitle {
  padding: 0px 10px;
  margin: 12px 0;

  font-family: "SF UI Text","Segoe UI";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */
  letter-spacing: -0.02em;

  color: #000000;

}

.resultsListSearchSectionTitle {

  padding: 0 10px;
  margin: 12px 0 0 0;

  font-family: "SF UI Text";
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  letter-spacing: -0.02em;
  text-transform: uppercase;

  color: #546BD6;
}

.resolvePendingChatsButton {
  width: 92%;
  margin: 0 16px;
}

.resolvePendingChatsButton:hover {
  border-color: #0FB878 !important;
}

@media (max-width: $mobile_width) {
  .resultsList {
    width: 100vw;
    scrollbar-width: 0px;
    -ms-overflow-style: none;
    height: calc(100% - 60px);
  }
  .resultsList::-webkit-scrollbar {
    display: none;
  }
  .filtersBlock {
    width: 100vw;
  }
}

.clearAllButton {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 12px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.channelSelectMobile {
  [class="ant-select-selector"] {
    height: 32px !important;
  }
}

.timestamp {
  letter-spacing: 0;
  font-size: 11px;
  color: $primary_black;
  display: flex;
  align-items: center;
}