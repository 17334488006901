@import 'src/styles/colorVars';
@import 'src/styles/numbers';

.wrapper {
    display: grid;
    grid-template-rows: auto 1fr;
    border-left: 1px solid $primary_white;
    height: calc(100% - 7px);

    @media (max-width: $mobile_width) {
        height: 100%;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $header_height;
    min-height: $header_height;
    position: relative;
    min-width: 0px;
    padding: 0px 16px;
    z-index: 20;
}

.body {
    display: flex;
    flex-direction: column;
    padding: 0px;
    overflow-y: auto;

    [class='ant-tabs-nav'] {
        margin: 0 !important;
        position: sticky !important;
        top: 0;
        z-index: 10;

        &::before {
            opacity: 0.2;
        }
    }
    [class*='ant-tabs-tab'] {
        text-transform: uppercase;
        justify-content: center;
    }
    [class='ant-tabs-nav-list'] {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        width: 100%;
    }
    [class*='ant-tabs-content'] {
        height: 100%;
    }
}

.icon {
    height: 24px;
    width: 24px;
    margin: 0 10px;
    fill: $primary_grey;
    cursor: pointer;

    svg {
        height: 24px;
        width: 24px;
    }

    svg path {
        fill: $primary_grey;
    }

    &.inverted {
        fill: $secondary_black;
        svg path {
            fill: $secondary_black;
        }
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.tabs {
    [class='ant-tabs-tab-btn'] {
        color: $primary_grey;
        opacity: 0.8;
    }
    [class*='ant-tabs-tab-active'] {
        [class='ant-tabs-tab-btn'] {
            color: $primary_grey !important;
            opacity: 1;
        }
    }
    [class*='ant-tabs-ink-bar'] {
        background: $primary_grey;
    }

    &.inverted {
        [class*='ant-tabs-tab-active'] {
            [class='ant-tabs-tab-btn'] {
                color: $secondary_black !important;
            }
        }
        [class='ant-tabs-tab-btn'] {
            color: $secondary_black;
        }
        [class*='ant-tabs-ink-bar'] {
            background: $secondary_black;
        }
    }
}

.dateHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    color: $secondary_black;
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    &.inverted {
        color: $primary_grey;
    }
}

.mediaWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 8px;
    column-gap: 16px;

    padding: 8px 16px;
}

.docWrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 8px;

    padding: 8px 16px;
}

.mediaBox {
    width: 80px;
    height: 80px;
    position: relative;

    img,
    video {
        width: 100%;
        height: 100%;
        min-height: 100%;
        object-fit: cover;
        cursor: pointer;

        &:hover {
            filter: brightness(85%);
        }
    }

    &:hover {
        label {
            display: inline-flex !important;
        }
    }

    &.legacy {
        img,
        video {
            cursor: not-allowed;
            &:hover {
                filter: none;
            }
        }
    }
}

.docBox {
    width: 200px;
    margin: 0 auto;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    cursor: pointer;
    position: relative;

    &:hover:not(label) {
        filter: brightness(85%);
    }
}

.videoWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent;
    opacity: 1;

    &.loading {
        background: $primary_grey_bg;
        opacity: 0.5;
    }
}

.videoLoader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.downloadLoader {
    margin: 0 10px;
}

.mediaBoxCheckbox {
    position: absolute;
    top: 6px;
    right: 6px;

    &:not(.checked) {
        display: none !important;
    }

    [class*='ant-checkbox'] {
        top: 0 !important;
    }
}

.docBoxCheckbox {
    position: absolute;
    left: -48px;
}

.loading-icon {
    svg {
        height: 32px;
        width: 32px;
    }
}

.checkedBox {
    border-color: $primary_green !important;
}

.image,
.videoWrapper {
    border: 3px solid transparent;
}

.docBoxMessage {
    pointer-events: none;
    font-size: 14px;
    text-transform: lowercase;
}

.tooltipContainer {
    padding: 8px;
}

.tooltipInfo {
    font-size: 12px;
    color: $primary_grey;
    margin-bottom: 8px;
}

.tooltipAction {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: $primary_green;
    cursor: pointer;
}
