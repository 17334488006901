@import "src/styles/colorVars";

.wrapper {
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    gap: 8px;
    margin-bottom: 36px;
    text-transform: capitalize;
}

.warningBlock {
    display: flex;
    flex-direction: row;
}

.warningText {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.02em;
    margin: 0;
    color: $tertiary_black;
}

.warningCircleIcon {
    margin-right: 4px;
    margin-left: 1px;

    svg {
        fill: $tertiary_black;
    }
}
