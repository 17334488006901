@import "src/styles/numbers";
@import "src/styles/colorVars";

.container {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
}

.content {
    max-width: 900px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto;

}

.topBar {
    display: flex;
    width: 100%;
    position: absolute;
    top: 7%;
    height: 10%;
}

.topBarContent {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

}

.logo {
    height: 80px;
    width: 80px;

    &.custom {
        height: auto;
        width: auto;
        min-width: 80px;
        max-width: 160px;
    }
}

.centralTextBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mainText {
    font-family: SF UI Display,"Segoe UI";
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
    color: #2E313B;
}

.secondaryText {
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #3F3F3F;
    opacity: 0.7;
    position: relative;
    top: 24px;
}

.linkButton {
    height: 20px;
    width: 200px;
    margin-top: 24px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: $primary_green;
    opacity: 0.8;
    text-align: center;

    &:hover {
        opacity: 1;
    }
}

.formWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.authLabel {
    position: relative;
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: rgba(56, 60, 58, 0.7);

    margin-right: auto;

    margin-top: 8px;
    margin-bottom: 8px;
}

.input {
    width: 400px;
    height: 40px;
}

.submitWrapper {
    margin-top: 32px;
}

.submitSendPassword {
    width: 388px;
    min-width: 388px;
    height: 46px;
    cursor: pointer;
    background: var(--primary-green);
    border-radius: 2px;
    border: var(--primary-green);

    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;

    @media (max-width: $mobile_sm_width) {
        width: calc(100vw - 24px);
        min-width: calc(100vw - 24px);
    }
}

.submitSendPassword:hover {
    background: #00AB66;
}

.submitSendPasswordDisabled {
    background: #cccccc;
    cursor: auto;
}

.submitSendPasswordDisabled:hover {
    background: #cccccc;
}

.submitSendPassword:focus {
    outline: none;
}

.messagesBlock {
    min-height: 16px;
    margin-bottom: 12px;
}

.notificationText {
    position: relative;
    top: 16px;
    height: 16px;
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
}

.loginError {
    color: #e30101;
}

.loginEmailCheck {
    color: var(--primary-green);
}

.termsAndPrivacy {
    margin-top: 16px;
    height: 16px;
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
}

.languageSelect {
    position: relative;
}

@media (max-width: 600px) {

    .container {
        padding: 7% 10px 0 10px;
        box-sizing: border-box;
        justify-content: flex-start;
    }

    .topBar {
        height: auto;
        position: relative;
        top: 0;
    }

    .topBarContent {
        flex-direction: column;
    }

    .logo {
        position: relative;
        left: auto;

        &.custom {
            margin-bottom: 12px;
        }
    }

    .passwordInputBlock {
        width: 100%;
    }

    .passwordInput, .emailInput, .submitSendPassword {
        width: 100%;
        box-sizing: border-box;
    }

    .languageSelect {
        right: 0;
        top: 0;
    }
}

.loginMethodWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 24px;
    margin-top: 24px;

    @media (max-width: $mobile_width) {
        margin-top: 32px;
        gap: 8px;
    }
}

.loginMethodTitle {
    font-size: 18px;
    margin: 0;
}