@import 'src/styles/colorVars';
@import 'src/styles/numbers';

$input_width: 15rem;

.wrapper {
    height: $compose_height;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: $tertiary_grey_bg;

    &.rtl {
        direction: rtl;

        .preffix {
            border-radius: 0;
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
        }

        .suffix {
            border-radius: 0;
            border-top-left-radius: 25px;
            border-bottom-left-radius: 25px;
        }

        @media (max-width: $mobile_sm_width) {
            .preffix {
                border-radius: 0;
                border-top-left-radius: 25px;

                &::before {
                    left: -25px;
                    bottom: 0;
                }

                span {
                    left: -42px;
                }
            }

            .suffix {
                border-radius: 0;
                border-bottom-right-radius: 25px;

                &::before {
                    right: -25px;
                    top: 0;
                }

                span {
                    right: -42px;
                }
            }

            .input {
                border-radius: 0;
                border-top-left-radius: 25px;
                border-bottom-right-radius: 25px;
            }
        }
    }

    @media (max-width: $mobile_width) {
        height: $compose_height + 2 * 42px;

        .contentContainer {
            max-height: 3 * 42px;
            height: 3 * 42px;
        }

        &.twoRows {
            height: $compose_height + 42px;

            .contentContainer {
                max-height: 2 * 42px;
                height: 2 * 42px;
            }
        }

        &.oneRow {
            height: $compose_height;

            .contentContainer {
                max-height: 42px;
                height: 42px;
            }
        }
    }
}

.content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    gap: 8px;
    width: 100%;
    height: 100%;
    max-width: $desktop_width;
    padding: 12px 24px;

    @media (max-width: $mobile_width) {
        padding: 8px;
    }

    @media (min-width: $mobile_width) and (max-width: $desktop_width) {
        padding: 12px 8px;
    }
}

.messageIcon {
    width: 20px;
    height: 20px;
    fill: $primary_grey;

    &:not(&.disabled):hover {
        cursor: pointer;
        fill: $primary_green;
    }
}

.sendButton {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 64px;
    max-width: 64px;
    height: 40px !important;
    min-height: 40px !important;
    transition: background 0.15s ease;

    &[disabled] {
        background: #f5f5f5 !important;
        color: #00000040 !important;
    }

    &.invertColors:not([disabled]) {
        color: $primary_white !important;
    }

    @media (max-width: $mobile_width) {
        height: 100% !important;
        margin-left: 8px;
        width: 32px;
        max-width: 32px;
    }
}

.inputWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: width 0.3s ease;

    width: $input_width;
    max-width: $input_width * 3;

    input {
        width: 100%;
        height: 40px !important;
    }

    @media (max-width: $mobile_width) {
        width: 100%;
    }

    @media (min-width: $mobile_width) and (max-width: $desktop_width) {
        width: 100%;
        min-width: 10rem;
    }

    @media (min-width: $desktop_width) {
        min-width: $input_width * 3;
    }
}

.input {
    font-size: 14px;
    background-color: $primary_white !important;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 4px;
    height: 42px;
    max-height: 42px;
    border-radius: 0 !important;
    direction: ltr;

    &[disabled] {
        background: #f5f5f5;
        cursor: not-allowed;
    }

    &.rtl {
        direction: rtl;
    }
    
    textarea {
        white-space: nowrap;
        overflow-y: hidden;

        &::placeholder {
            font-size: 13px;
        }
    }

    @media (max-width: $mobile_width) {
        border-bottom-left-radius: 25px !important;
        border-top-right-radius: 25px !important;
        padding-left: 5px !important;

        &.rtl {
            border-radius: 0 !important;
            border-top-left-radius: 25px !important;
            border-bottom-right-radius: 25px !important;
            padding-right: 5px !important;
        }
    }
}

.extraInfo {
    background-color: $primary_grey;
    min-width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    position: relative;
    white-space: nowrap;

    div {
        z-index: 1;
    }

    span {
        display: none;
    }

    @media (max-width: $mobile_width) {
        span {
            display: block;
            width: 42px;
            height: 42px;
            border-radius: 25px;
            background: $tertiary_grey_bg;
            position: absolute;
            z-index: 2;
        }

        &::before {
            background-color: $primary_grey;
            position: relative;
            width: 25px;
            height: 25px;
            z-index: 1;
            content: '';
            position: absolute;
        }
    }
}

.preffix {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    padding-left: 8px;

    @media (max-width: $mobile_width) {
        align-self: flex-start;
        border-radius: 0;
        border-top-right-radius: 25px;

        &::before {
            right: -25px;
            bottom: 0;
        }

        span {
            right: -42px;
        }

        &.rtl {
            &::before {
                right: auto;
            }

            span {
                right: auto;
            }
        }
    }
}

.suffix {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    padding-right: 8px;

    @media (max-width: $mobile_width) {
        align-self: flex-end;
        border-radius: 0;
        border-bottom-left-radius: 25px;

        &::before {
            left: -25px;
            top: 0;
        }

        span {
            left: -42px;
        }
    }
}

.contentContainer {
    max-height: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    width: 100%;

    @media (max-width: $mobile_width) {
        flex-direction: column;
    }
}

.emojisSelectButton {
    svg {
        pointer-events: none;
        height: 20px;
        width: 20px;
    }
}

.prefixContainer {
    display: flex;
    align-items: center;
    height: 100%;

    &.hide {
        display: none;
    }

    @media (max-width: $mobile_width) {
        align-self: flex-start;
    }
}

.emojiContainer {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.emojiPalette {
    @media (max-width: $mobile_width) {
        bottom: 102px;
    }
}

.textAreaWrapper {
    width: 100%;
}