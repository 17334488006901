@import 'src/styles/colorVars';

.container {
    display: flex;

    &.rtl {
        [class="ant-select-selection-item"] {
            direction: rtl;
            padding-right: 0 !important;
            padding-left: 18px !important;
            justify-content: right !important;

            &::after {
                content: none !important;
            }
        }
        [class="ant-select-arrow"] {
            right: auto;
            left: 18px;
        }
    }

    & > [class*="ant-list"] {
        width: 100%;
    }
}

.selectContainer {
    width: 100%;

    [class='ant-select-selector'] {
        border-left-width: 0 !important;
        border-right-width: 0 !important;
        border-radius: 0 !important;
        padding: 0 24px !important;
    }

    &.withBorders {
        [class='ant-select-selector'] {
            border-left-width: 1px !important;
            border-right-width: 1px !important;
        }
    }

    [class='ant-select-arrow'] {
        right: 24px;
    }

    [class='ant-select-selection-item'] {
        gap: 16px;
    }

    svg {
        color: $primary_black;
        width: 16px;
        height: 16px;
    }

    &:hover {
        [class="ant-select-arrow"] svg {
            fill: $primary_green;
        }
    }

    &.small {
        [class='ant-select-selector'] {
            height: 32px !important;
            padding: 0 12px !important;
        }

        [class='ant-select-arrow'] {
            right: 12px;
        }

        [class='ant-select-selection-item'] {
            gap: 10px;
        }

        .selectIcon {
            width: 18px;
            height: 18px;
            min-width: 18px;
        }
    }
}

.selectOption {
    padding: 0 24px !important;
    height: 40px;
    min-width: 0;

    [class='ant-select-item-option-content'] {
        gap: 16px;
    }

    &:not(:last-child) {
        border-bottom: 1px solid $primary_grey_border;
    }

    &.small {
        height: 32px;

        .selectIcon {
            width: 18px;
            height: 18px;
            min-width: 18px;
        }
    }
}

.selectIcon {
    width: 22px;
    height: 22px;
    min-width: 22px;
}

.selectTitle {
    color: $primary_black;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
}
