.messageText {
    width: 100%;
    letter-spacing: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    word-wrap: break-word;
    position: relative;
    margin-top: 4px;
    margin-bottom: 0;
    color: #353535;
}

.whiteText {
    color: #ffffff;
}

.deletedText {
    text-decoration: line-through;
}

.messageText a {
    color: var(--link-color);
}

.messageText a:hover {
    color: var(--link-color-hover);
}

.link {
    word-break: break-all;
}

.list {
    padding: 0 16px;
    margin-bottom: 0;
}

.listTitle {
    display: flex;
    align-items: center;
    margin: 4px 0;
}
