@import 'src/styles/colorVars';
@import 'src/styles/numbers';

.container {
    display: flex;
    flex-direction: column;
    padding: 12px 24px;
    border-bottom: 2px solid $primary_grey_border;

    &.loading {
      user-select: none;
      pointer-events: none;
      opacity: 0.5;
    }

    @media (max-width: $mobile_width) {
      padding: 12px;
    }
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.searchContainer {
    display: flex;
    flex-direction: column;
    color: $tertiary_black;
    gap: 4px;
}

.icon {
  svg {
    width: 16px;
    min-width: 16px;
    height: 16px;
    fill: $tertiary_black;
    cursor: pointer;
  }

  &.disconnect {
    &:hover {
      svg {
        fill: $primary_red;
      }
    }

    @media (max-width: $mobile_width) {
      position: relative;
      left: 6px;
    }
  }
}

.searchLabel {
  font-size: 12px;
}

.channelIcon {
    width: 22px;
    height: 22px;
    min-width: 22px;
}

.accountsContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 8px 0;
}

.accountContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: calc(320px - 48px);
  gap: 8px;

  @media (max-width: $mobile_width) {
    max-width: calc(320px - 24px);
  }
}

.account {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
    padding: 8px 12px;
    height: 40px;
    border: 1px solid $antd_border;
    max-width: calc(100% - 24px);
    cursor: pointer;

    &.searchItem {
      max-width: 100%;
    }

    &:hover {
      border-color: $primary_green;
    }

    @media (max-width: $mobile_width) {
      max-width: auto;
    }
}

.accountLabel {
  color: $primary_black;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.searchSelect {
  input {
    height: 40px !important;
  }
}