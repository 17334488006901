@import 'src/styles/colorVars';

.myinput-wrapper {
    padding: 8px;
    border-radius: 8px;
    outline: none;

    &.rtl {
        direction: rtl;
    }

    &.full-width {
        width: 100%;
    }

    &.large {
        height: 48px;
        font-size: 14px;
    }
    &.middle {
        height: 40px;
    }

    .ant-input-clear-icon {
        display: flex;
        align-items: center;
    }

    &:hover,
    &:focus,
    &:active {
        border-color: $primary_green !important;
        box-shadow: none;

        span[class='ant-input-prefix'] svg {
            fill: $primary_green !important;
        }
    }
}

.myinput-loading {
    font-size: 20px;

    svg {
        color: $secondary_black;
        fill: $secondary_black;
    }
}

.myinput-textarea-wrapper {
    resize: none;
    background: $secondary_grey_bg;
    padding: 8px !important;
    border-radius: 8px !important;

    &.rtl {
        direction: rtl;
    }

    &.full-width {
        width: 100% !important;
    }
    
    &:focus,
    &:active {
        outline: none !important;
        box-shadow: none !important;
    }
}
